import React from "react"
import Layout from "../components/layout"
import InnerPageLayout from "../components/inner-page-layout"
import OfficialCard from "../components/official-card"

export default function ClubOfficials() {
  return ( 
    <Layout>
        <section className="container">
        <h1>Club Officials</h1>
        <InnerPageLayout>
            <img src="stock-football-3.jpg" className="img-fluid mb-4 inner-page-hero" />
            <p className="lead">Leeds City Juniors FC is run by volunteers.</p>
            <p>If you are interested in getting involved then please contact our club secretary.</p>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              <div className="col">
                <OfficialCard title="Chairperson" member="Jonathan White"></OfficialCard>
              </div>
              <div className="col">
                <OfficialCard title="Club Secretary" member="Angeline McClelland" email="secretary.leedscityjnrs@gmail.com" telephone="07846 364141"></OfficialCard>
              </div>
              <div className="col">
                <OfficialCard title="Child Welfare Officer" member="Abigail White" email="welfareofficer.leedscityjnrs@gmail.com" telephone="07555 456439"></OfficialCard>
              </div>
              <div className="col">
                <OfficialCard title="Treasurer" member="Sinisa Savic" email="treasurer.leedscityjnrs@gmail.com"></OfficialCard>
              </div>
              <div className="col">
                <OfficialCard title="Coaches Representative" member="Sinisa Savic" email="sinisa_savic@hotmail.co.uk"></OfficialCard>
              </div>
              <div className="col">
                <OfficialCard title="Vice Chair" member="Mike Inman"></OfficialCard>
              </div>
              <div className="col">
                <OfficialCard title="Enquiries" member="General Enquiries" email="leedscityjnrs@gmail.com"></OfficialCard>
              </div>
              <div className="col">
                <OfficialCard title="Website Coordinator" member="Chet Mistry" email="web@leedscityjuniors.org.uk"></OfficialCard>
              </div>
            </div>
        </InnerPageLayout>
      </section>
    </Layout>
  );
}
