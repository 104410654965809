import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

export default function OfficialCard(props) {

    return (
        <div className="card text-dark bg-light mb-3 official-card">
           <div className="card-header">{props.title}</div>
           <div className="card-body">
               <h2 className="card-title">{props.member}</h2>
                <h5 className="card-subtitle">
                { props.email && <a className="mx-1" href={'mailto:' + props.email}><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></a>}
                { props.telephone && <a className="mx-1" href={'tel:' + props.telephone}><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon></a>}
                </h5>
            </div>
        </div>
    );
}
